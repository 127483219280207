import React, { useState, useEffect, useReducer } from 'react';
import styles from '../../css/ProgressionEditor.module.css';

import { Progression } from '../../Helpers/Types/ProjectTypes';
import { ChordEditorAction } from '../../Helpers/Types/ChordEditorTypes';
import { NoteName } from '../../Helpers/Types/MusicTypes';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { Suggestor } from './Suggestor';
import { toast } from 'react-hot-toast';

type Props = {
	progression: Progression,
	loopIndex: number,
}

// TODO: John
const reducer = (state: Progression, action: ChordEditorAction) => {
	const copiedState = [...state];
	switch (action.type) {
		case "SWAP_CHORD":
			copiedState[action.payload.sourceIndex].chord = action.payload.targetChord;
			return [...copiedState];
		// case "CHANGE_ITERATION":
		// 	copiedState[action.payload.sourceIndex].chordLength = action.payload.targetChord;
		// 	return [...copiedState];
		case "DELETE_CHORD":
			return copiedState.filter((_chord, index) => index !== action.payload.indexToDelete);
		case "ADD_CHORD":
			return [...copiedState, action.payload.chordData]
		default:
	}
	return {...state};
}

const ChordEditor = ({ progression, loopIndex }: Props) => {
	const [editedProgression, progressionDispatch] = useReducer(reducer, progression);
	const [sourceIndex, setSourceChord] = useState(0);
	const [targetChord, setTargetChord] = useState<NoteName>("C");
	const [notesInChord, setNotesInChord] = useState([]);
	const [key, setKey] = useState("A");
    const [mode, setMode] = useState("Ionian");

	// console.log({...{sourceIndex, targetChord}})
	console.log(progression)

	useEffect(() => {
		
	}, [])

	const renderKeys = () => {
		const notes: NoteName[] = ["A", "A#", "B", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#"];
		return notes.map((note) => <button onClick={() => setKey(note)}>{note}</button>)
	}

	const renderModes = () => {
		const modes = ["Ionian", "Dorian", "Phrygian", "Lydian", "Mixolydian", "Aeolian", "Locrian"];
		return modes.map(mode => <button onClick={() => setMode(mode)}>{mode}</button>)
	}

	const renderLoopChords = () => {
		// editedProgression.map(chord => console.log(chord))
		return editedProgression.map((chordData, index) => <button onClick={() => setSourceChord(index)}>{chordData.chord}</button>)
	}
	
	const renderSuggestedChords = () => {
		// getSuggestions()
		// const chords: NoteName[] = ["A", "A#", "B", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#"];\
		const diatonics = Suggestor.diatonics(key, mode)

		return diatonics.map((element, index) =>  
			<button className={styles.suggestionOption} onClick={() => console.log(element.value)}>{element.key}</button>
		)

	}

	const handleSaveLoop = (editedProgression: Progression) => {
		// dispatch({
		// 	type: "SAVE_LOOP",
		// 	payload: {
		// 		loopIndex: loopIndex,
		// 		editedLoop: { ...loop, progression: editedProgression }
		// 	}
		// })
	}

	const handleChordToNote = () => {
		// Convert a chord into its note components here;
		return [];
	}

    return (
    <div className={styles.mainContainer}>
		
		<button onClick={() => console.log(Suggestor.allChords())}>log all chords</button>

        <div className={styles.selectors}>
			{/* <div style={{paddingTop: '1rem'}}> */}
			<FormControl sx={{ m: 1, minWidth: 80 }} size="small">
				<InputLabel id="key-selector">Key</InputLabel>
				<Select
					labelId="key-selector"
					id="key-selector"
					value={key}
					label="Key"
					// onChange={(event) => setKey(event.target.value)}
					onChange={(event: SelectChangeEvent) => setKey(event.target.value)}
				>
					<MenuItem value={"A"}>A</MenuItem>
					<MenuItem value={"A#"}>A#</MenuItem>
					<MenuItem value={"B"}>B</MenuItem>
					<MenuItem value={"C"}>C</MenuItem>
					<MenuItem value={"C#"}>C#</MenuItem>
					<MenuItem value={"D"}>D</MenuItem>
					<MenuItem value={"D#"}>D#</MenuItem>
					<MenuItem value={"E"}>E</MenuItem>
					<MenuItem value={"F"}>F</MenuItem>
					<MenuItem value={"F#"}>F#</MenuItem>
					<MenuItem value={"G"}>G</MenuItem>
					<MenuItem value={"G#"}>G#</MenuItem>
				</Select>
			</FormControl>
			{/* </div> */}

			{/* <div style={{paddingTop: '1rem', width: '8rem'}}> */}
			<FormControl sx={{ m: 1, minWidth: 130 }} size="small">
			<InputLabel id="mode-selector">Mode</InputLabel>
				<Select
					labelId="mode-selector"
					id="mode-selector"
					value={mode}
					label="Mode"
					// onChange={() => setKey(this.value)}
					onChange={(event: SelectChangeEvent) => setMode(event.target.value)}
				>
					<MenuItem value={"Ionian"}>Ionian</MenuItem>
					<MenuItem value={"Dorian"}>Dorian</MenuItem>
					<MenuItem value={"Phrygian"}>Phrygian</MenuItem>
					<MenuItem value={"Lydian"}>Lydian</MenuItem>
					<MenuItem value={"Mixolydian"}>Mixolydian</MenuItem>
					<MenuItem value={"Aeolian"}>Aeolian</MenuItem>
					<MenuItem value={"Locrian"}>Locrian</MenuItem>
				</Select>
			</FormControl>
			{/* </div> */}
        </div>

        <div className={styles.loopChords}>
				{/* { editedProgression.map(chordData => <button onClick={() => setTargetChord(chordData.chord)}>{chordData.chord}</button>) } */}
			{renderLoopChords()}
        </div>

        <div className={styles.loopChordNotes}>
				{/* { editedProgression.map(chordData => <button onClick={() => setTargetChord(chordData.chord)}>{chordData.chord}</button>) } */}
				{renderSuggestedChords()}
	
        </div>

		{/* <div className={styles.confirm}>
			<button onClick={() => handleSaveLoop(editedProgression)}>
				CONFIRM SELECTION
			</button>
		</div> */}

        <div className={styles.bottomContainer}>
            <div className={styles.swapArea}>

                <div className={styles.suggestions}>
                    <p>SUGGESTIONS</p>
                    <div className={styles.suggestionList}>
                        {renderSuggestedChords()}
                    </div>
                </div>

                <div className={styles.swapDescription}>
                    {/* <h1>SWAP OUT CHORDS</h1> */}
                    <div>
						{progression[sourceIndex].chord}
						<ArrowRightAltIcon/>
						{targetChord}
							{/* <p>{sourceIndex} -{'>'} {targetChord}</p> */}
                        <p>This is a simple description of why this chord swap is a good idea</p>
							<button onClick={() => progressionDispatch(
									{
										type: "SWAP_CHORD", 
										payload: { 
											sourceIndex: sourceIndex, 
											targetChord: targetChord
										}
									}
									)
								}
							>
								SWAP
							</button>
                    </div>
                </div>

            </div>

			<div className={styles.bottomButtons}>
				<div className={styles.refresh}>
					<button>REFRESH</button>
				</div>

				<div className={styles.confirm}>
					<button onClick={() => (
									handleSaveLoop(editedProgression),
									toast.success("Loop has been updated"))
									}>
						CONFIRM SELECTION
					</button>
				</div>
			</div>
        </div>
    </div>
  );
};

export default ChordEditor;