import React, { useState } from 'react';
import axios from 'axios';

import {
	Dialog, 
	TextField,
	Button,
	Typography
} from '@mui/material';

type Props = {
	toggle: () => void;
	open: boolean;
}

const ForgotPassword = ({toggle, open}: Props) => {
	
	const [email, setEmail] = useState("");
	const [msg, setMsg] =  useState("");
	const [submitted, setSubmit] = useState(false);
	
	const handleSubmit = async () => {

		try {
			const res = await axios.post("https://chordeo-grapher.herokuapp.com/user/reset-password", { email })
			setSubmit(true);
			setMsg(`Password reset link sent to ${email}.`);
		} catch (err) {
			setMsg("Something went wrong.");
		}

	};

	const formChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setEmail(e.target.value);
	};

	// const style = {
	// 	display:'flex', flexDirection:'column',
	// 	textAlign:'center', justifyContent:'center',
	// 	margin:'4vw', borderRadius:'20px', border:'1px',
	// };

	return (
		<Dialog open={open} onClose={toggle} >
			{/* <div style={style}> */}
			<div 
				style={{
					display:'flex', flexDirection:'column',
					textAlign:'center', justifyContent:'center',
					margin:'4vw', borderRadius:'20px', border:'1px',
				}} >
				{
					!submitted?
					<div 
						style={{
							display:'flex', flexDirection:'column',
							textAlign:'center', justifyContent:'center',
							margin:'4vw', borderRadius:'20px', border:'1px',
						}} 
					>
						<Typography style={{width: '40vw'}} variant="h4">
							Enter Recovery Email
						</Typography>
						{/* <h1 style={{width:'40vw'}} >Enter Recovery Email</h1> */}
						<TextField placeholder="Email" onChange={formChange} />
						<Button onClick={handleSubmit}>Try Reset</Button>
					</div>
					: null
				}
				<Typography>{msg}</Typography>
			</div>
		</Dialog>
	);
}

export default ForgotPassword;