import React, { useState } from 'react'
import ForgotPassword from './Login/ForgotPassword'
import LoginSignup from './Login/LoginSignup'

import { Button, Typography } from '@mui/material'
import styles from '../css/NavBar.module.css'

type Props = {
	isLoggedIn: boolean; 
	nickname: string;
	doSignIn: (email: string, password: string) => Promise<void>;
	doSignOut: () => Promise<void>;
}

const NavBar = ({ isLoggedIn, nickname, doSignIn, doSignOut }: Props) => {

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [forgPass, setForgPass] = useState(false);

	const toggleForgotPop = () => {
		setForgPass(!forgPass);
	}

	const notLoggedIn = () => {

		return (
			<div className={styles.notLoggedIn}>
				{forgPass ? <ForgotPassword open={forgPass} toggle={toggleForgotPop} /> : null}

				{/* <input className={styles.input} placeholder="Email" onChange={(e) => setEmail(e.target.value)}/> */}
				{/* <input className={styles.input} placeholder="Password" onChange={(e) => setPassword(e.target.value)}/> */}

				<div className={styles.buttonGroup}>
					{/* <button className={styles.loginButton}>Login</button> */}
					{/* <button className={styles.loginButton}>Sign Up</button> */}
					{/* <LoginSignup buttonText="Login" /> */}
					{/* <button className={styles.loginButton} onClick={() => doSignIn(email, password)}>Sign In</button> */}
					<LoginSignup buttonText="Login" doSignIn={doSignIn} />
					<LoginSignup buttonText="Sign Up" doSignIn={doSignIn} />
					{/* <button className={styles.forgotButton}>Forgot Password</button> */}
				</div>
			</div>
		)
	}

	const loggedIn = () => {

		return (
			<div className={styles.notLoggedIn}>
				<Typography variant="h6" className={styles.welcomeMessage} >Welcome, {nickname}!</Typography>
				<Button onClick={doSignOut} variant="contained">Sign Out</Button>
			</div>
		)
	}

	return (
		<div className={styles.main}>
			<h1 className={styles.title}><span style={{color: '#1565c0'}}>Chord</span>eographer</h1>
			<div className={styles.submain}>
				{ isLoggedIn ? loggedIn() : notLoggedIn() }
			</div>
		</div>
	)
}

export default NavBar