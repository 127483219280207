import axios from 'axios';
import { Project } from "../Helpers/Types/ProjectTypes";

const baseURL = process.env.REACT_APP_BASE_URL;

export const API = axios.create({
	baseURL,
	withCredentials: true
});

export const defaultProject: Project = {
	title: 'Untitled Project',
	key: 'C#',
	mode: "Major",
	loops: [],
	bpm: 100
};

// Depreciated.
export const getUserIp = async () => {

	try {

		const response = await axios.get('https://api.ipify.org?format=json');
		return response.data.ip;

	} catch (error) {

		console.log('Error while getting ip:', error);
		return 'NO IP';
	}
}

export const getProjects = async () => {
	
	try {

		const response = await API.get('/user/get-projects');
		return response.data.projects;

	} catch (error) {
		
		console.log('Error while getting projects:', error);
		return null;
	}
}

export const doProjectUpload = async (project: Project) => {

	try {

		if (project.pid) {
			console.log('Cannot upload a saved project. Use project update instead.');
			return null;
		}

		const response = await API.post('/user/upload-project', { project });
		return response.data.project;

	} catch (error) {

		console.log('Error while saving project:', error);
		return null;
	}
}

export const doProjectUpdate = async (project: Project) => {
	
	try {

		if (!project.pid) {
			console.log('Cannot update a project that has not been saved.');
			return null;
		}

		const response = await API.put('/user/update-project', { project });
		return response.data.project;

	} catch (error) {

		console.log('Error while saving project:', error);
		return null;
	}
}

export const doProjectPatch = async (project: Project) => {

	try {

		if (!project.pid) {
			console.log('Cannot update a project that has not been saved.');
			return null;
		}

		const response = await API.patch('/user/update-project', { project });
		return response.data.project;

	} catch (error) {

		console.log('Error while saving project:', error);
		return null;
	}
}

export const doProjectDelete = async (pid: string) => {

	try {

		if (!pid) {
			console.log('Invalid project id.');
			return null;
		}

		const response = await API.patch('/user/delete-project', { pid });
		return response.data.project;

	} catch (error) {

		console.log('Error while saving project:', error);
		return null;
	}
}