import React, { useState } from 'react';
import { API } from '../../api/api';

import { TextField, Typography } from '@mui/material';
import styles from '../../css/NavBar.module.css';

type Props = {
	handleClose: () => void;
}

const SignUpForm = ({ handleClose }: Props) => {

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPass, setConfirmPass] = useState('');
	const [nickname, setNickname] = useState('');
	const [errMsg, setErr] = useState('');

	const setAndCheckConfirmPass = (pass: string) => {

		setConfirmPass(pass);
		if (password === pass)
			setErr('');
		else
			setErr('Passwords do not match.');
	}

	const doSignUp = () => {

		if (password !== confirmPass) {
			console.log('Could not sign up. Passwords did not match.');
			setErr('These passwords do not match.');
			return;
		}

		API.post('/user/signup', { nickname, email, password })
			.then((response) => {
				setErr(response.data.message);
			})
			.catch((error) => {
				console.log(error);
				setErr('Could not sign up.');
			});
	}

	return (
		<div className={styles.signupForm}>
			<TextField
				className={styles.textField}
				label="Nickname"
				variant="filled"
				required
				value={nickname}
				onChange={(e) => {setNickname(e.target.value); setErr("")}}
			/>
			<TextField
				className={styles.textField}
				label="Email"
				variant="filled"
				type="email"
				required
				value={email}
				onChange={(e) => {setEmail(e.target.value); setErr("")}}
			/>
			<TextField
				className={styles.textField}
				label="Password"
				variant="filled"
				type="password"
				required
				value={password}
				onChange={(e) => {setPassword(e.target.value); setErr("")}}
			/>
			<TextField
				className={styles.textField}
				label="Confirm Password"
				variant="filled"
				type="password"
				required
				value={confirmPass}
				onChange={(e) => {setAndCheckConfirmPass(e.target.value)}}
			/>
			<Typography>{errMsg}</Typography>
			<div className={styles.formButtons}>
				<button className={styles.cancelButton} onClick={handleClose}>Cancel</button>
				<button className={styles.loginButton} onClick={doSignUp}>Submit</button>
			</div>
		</div>
	)
}

export default SignUpForm