import React, { useState, useEffect } from 'react'

// Styling
import styles from '../../css/Loop.module.css';

// Custom Components
import ChordEditor from './ChordEditor';
import useModal from '../../Helpers/CustomHooks/useModal';
import GenericModal from '../../Helpers/Generics/GenericModal';
import { MainPanelAction } from '../../Helpers/Types/MainPanelTypes';
import { Loop, Progression, Section } from '../../Helpers/Types/ProjectTypes';

// Installed packages
import Soundfont from 'soundfont-player'
import { note, Chord } from '@tonaljs/tonal'; 
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import GenericConfirmation from '../../Helpers/Generics/GenericConfirmation';
// import SoundfontProvider from '../Sound/SoundfontProvider';import GenericConfirmation from '../../Helpers/Generics/GenericConfirmation';

// import DimensionsProvider from './DimensionsProvider';


type Props = {
	dispatch: React.Dispatch<MainPanelAction>,
	loop: Loop,
	loopIndex: number,
	player: Promise<Soundfont.Player>
}

const ChordBox = (chordObj: Section) => {
	return (
		<div className={styles.chord}>
			{chordObj.chord}
		</div>
	)
}

const LoopBox = ({dispatch, loop, loopIndex, player}: Props) => {

	// webkitAudioContext fallback needed to support Safari
	const audioContext = new (window.AudioContext)();
	const soundfontHostname = 'https://d1pzp51pvbm36p.cloudfront.net';
	var ac = new AudioContext()

	const {open: openEdit, handleOpen: handleOpenEdit, handleClose: handleCloseEdit} = useModal();
	const {open: openConfirm, handleOpen: handleOpenConfirm, handleClose: handleCloseConfirm} = useModal();

	const handleSaveLoop = (editedProgression: Progression) => {
		dispatch({
			type: "SAVE_LOOP",
			payload: {
				loopIndex: loopIndex,
				editedLoop: { ...loop, progression: editedProgression }
			}
		})
	}

	function testPlay () {	  
		// The first step is always create an instrument:
		// Soundfont.instrument(ac, 'acoustic_grand_piano').then(function (piano) {
		player.then(function (piano) {
		  piano.stop()
		
		  for(let i = 0; i < loop.progression.length; i++)
		  {
			let notes = Chord.get(loop.progression[i].chord).notes
			piano.play(notes[0] + '4', ac.currentTime + i, { duration: 0.25 })
			piano.play(notes[1] + '4', ac.currentTime + i, { duration: 0.25 })
			piano.play(notes[2] + '4', ac.currentTime + i, { duration: 0.25 })
		  }
		})
	  
	  }

	console.log(loop.progression)

	return (
        <div className={styles.loopContainer}>
			<div style={{display: 'flex', flexDirection: 'row'}}>
				<div style={{ marginLeft: '2%',  marginTop: '1%'}}>
					{loopIndex + 1}
				</div>
				<div className={styles.loop}>
					{loop.progression.map(section => <button className={styles.chordButton}>{section.chord}</button>)}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', marginRight: '5%', marginTop: '3.5%', marginLeft: 'auto', overflowX: 'hidden'}}>	
					<EditIcon sx={{ color: 'blue', marginLeft: '0.1vw' }} onClick={handleOpenEdit}></EditIcon>
					<PlayArrowIcon sx={{ color: 'green', marginLeft: '-0.1vw' }} onClick={() => testPlay()}></PlayArrowIcon>
					<DeleteIcon sx={{ color: 'red' }} onClick={ () => dispatch({ type: "DELETE_LOOP", payload: loopIndex }) }></DeleteIcon>
				</div>
			</div>


			<GenericModal open={openEdit} handleOnClose={handleCloseEdit}>
				<ChordEditor progression={loop.progression.map(l => ({...l}))} loopIndex={loopIndex}/>
				{/* <ChordEditor progression={loop.progression.map(l => ({...l}))} loopIndex={loopIndex} handleSaveLoop={handleSaveLoop}/> */}
			</GenericModal>

			<GenericConfirmation open={openConfirm} handleOnClose={handleCloseConfirm} handleOnConfirm={() => console.log("Hit Confirm")}/>

			{/* <GenericModal open={openEdit} handleClose={handleCloseEdit}></GenericModal> */}

			{/* {loop.progression.map(chord => console.log(chord))} */}
			
			{/* {loop.progression.map((chord) => <ChordBox chord={chord}/>)} */}

			
        </div>
	)
}

export default LoopBox