import React, { useReducer } from 'react';

// Styling
import styles from '../../css/MainPanel.module.css';
import stylesDark from '../../css/MainPanel.module.css';

// Custom Components
import LoopBox from './Loop';
import ProjectControls from '../ProjectControls';
import KeyModeBPMControls from '../KeyModeBPMControls';
import { Project } from "../../Helpers/Types/ProjectTypes"
import { MainPanelAction } from '../../Helpers/Types/MainPanelTypes';

// Installed packages
import { Chord } from '@tonaljs/tonal'; 
import Soundfont from 'soundfont-player'
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

/*
	1. Define your props in a file
	2. Go to the parents of this current file
	3. Determine the types of the props you want passed in
*/

let theme = 'light'

type Props = {
	isLoggedIn: boolean; 
	project: Project;
	setProject: (project: Project) => void;
	projects: Project[];
	resetProjectList: () => void;
}

const initialState = {
	title: "Unsaved Project",
	loops: [],
	key: "C",
	mode: "Minor",
	dateMade: new Date().toString()
}

const sampleData: Project[] = [
	{
		title: "test loop",
		loops: [
			{
				name: "First Bar",
				placement: 1,
				iterations: 3,
				progression: [
					{
						chord: "C",
						chordLength: .25
					},
					{
						chord: "A",
						chordLength: .25
					},
					{
						chord: "G",
						chordLength: .25
					},
					{
						chord: "C",
						chordLength: .25
					},
				],
			},

		],
		key: "C",
		mode: "Major",
		bpm: 100,
		dateMade: new Date()
	},
];

const reducer = (state: Project, action: MainPanelAction) => {
	switch (action.type) {
		case "CHANGE_PROJECT":
			return action.payload;
		case "CHANGE_TITLE":
			return { ...state, title: action.payload };
		case "CHANGE_KEY":
			return { ...state, key: action.payload };
		case "CHANGE_MODE":
			return { ...state, mode: action.payload };
		case "ADD_LOOP": {	
			const temp = [0, 0, 0, 0]
			return { ...state, loops: [...state.loops, 
				{
					name: "New Loop",
					placement: 1,
					iterations: 3,
					progression: temp.map(x =>( { chord: state.key, chordLength: .25 }))
				}]
			}
		}
		case "DELETE_LOOP":
			return { ...state, loops: state.loops.filter((loop, index) => index !== action.payload) };
		// TODO: Logic to change loops
		case "SAVE_LOOP": {
			const { loopIndex, editedLoop } = action.payload
			console.log(state.loops)
			console.log(action.payload);
			const temp = [...state.loops];
			temp[loopIndex] = editedLoop;
			console.log(temp)
			return { ...state, loops: temp}
		}

		default:
	}

	return { ...state };
}

export default function MainPanel({ isLoggedIn, project, setProject, projects, resetProjectList }: Props) {
	
	// webkitAudioContext fallback needed to support Safari
	const audioContext = new (window.AudioContext)();
	const soundfontHostname = 'https://d1pzp51pvbm36p.cloudfront.net';
	var ac = new AudioContext()
	const player = Soundfont.instrument(ac, 'acoustic_grand_piano')

	const [projectInfo, dispatch] = useReducer(reducer, sampleData[0]);

	function testStopAll () {
		player.then(function (piano) {
			piano.stop()
			console.log("stopping")
		})
	}

	function testPlayAll () {	  
		
		// The first step is always create an instrument:
		// Soundfont.instrument(ac, 'acoustic_grand_piano').then(function (piano) {
		//   piano.stop()
		
		//   console.log(projectInfo.loops)
		// 	let time = 0
		//   for(let i = 0; i < projectInfo.loops.length; i++)
		//   {
		// 	console.log("length is: ", projectInfo.loops.length)
		// 	let numChords = projectInfo.loops[i].progression.length;
		// 	console.log(numChords)
		//   	for(let j = 0; j < numChords; j++)
		//   	{
		// 		let notes = Chord.get(projectInfo.loops[i].progression[j].chord).notes
		// 		piano.play(notes[0] + '4', ac.currentTime + time, { duration: 1 })
		// 		piano.play(notes[1] + '4', ac.currentTime + time, { duration: 1 })
		// 		piano.play(notes[2] + '4', ac.currentTime + time, { duration: 1 })
		// 		time += 1;
		//   	}
		//   }

		// })


		
		player.then(function (piano) {
			piano.stop()
		
			let time = 0
			for(let i = 0; i < projectInfo.loops.length; i++)
			{	
				let numChords = projectInfo.loops[i].progression.length;
		  		for(let j = 0; j < numChords; j++)
		  		{
					let notes = Chord.get(projectInfo.loops[i].progression[j].chord).notes
					piano.play(notes[0] + '4', ac.currentTime + time, { duration: 1 })
					piano.play(notes[1] + '4', ac.currentTime + time, { duration: 1 })
					piano.play(notes[2] + '4', ac.currentTime + time, { duration: 1 })
					time += 1;
		  		}
		  	}
		})








	  }

	{theme == 'light' ? theme=styles: theme=stylesDark}
	return (
		<div className={styles.mainContainer}>
			<div className={styles.leftPanel}>
				<div className={styles.projectInfo}>
					<ProjectControls
						isLoggedIn={isLoggedIn}
						projects={projects}
						project={project}
						setProject={setProject}
						resetProjectList={resetProjectList}
					/>
					<KeyModeBPMControls
						project={project}
						setProject={setProject}
					/>
				</div>
				<div className={styles.keyAndMode}></div>
				<div className={styles.keyAndMode}></div>
			</div>

			<div className={styles.rightPanel}>
				<div className={styles.loopControls}>
					<p style={{marginLeft: '2vw'}}>Number Of Loops: {projectInfo.loops.length}</p>
					<div style={{marginTop: '0%', right: '0', marginRight: '2%', position: 'absolute', bottom: '0', paddingBottom: '1vh'}}>
						<PlayArrowIcon sx={{ color: 'white' }} onClick={() => testPlayAll()}></PlayArrowIcon>
						<StopIcon sx={{ color: 'white' }} onClick={() => testStopAll()}></StopIcon>
						<AddIcon onClick={() => dispatch({ type: "ADD_LOOP" })}></AddIcon>
					</div>
				</div>
				<div className={styles.loopContainer}>
					<div style={{marginTop: '6vh'}}></div>
					{ projectInfo.loops.map((loop, index) => (
						<div style={{width: '53vw', height: '0vh', marginBottom: '18vh', marginLeft: '3.5%'}}>
							<LoopBox { ...{ dispatch, loop, player}} loopIndex={index} />
						</div>
					))
					}
				</div>
			</div>
		</div>
	)
}
