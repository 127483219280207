import React from 'react';

import { IconButton, Button } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';

type Props = {
	deleteProject: () => Promise<void>;
}
const DeleteProject = ({ deleteProject }: Props) => {

	const [showConfirm, setConfirm] = React.useState(false);

	const doDeleteProject =  () => {
		
		deleteProject();
		setConfirm(false);
	}

	return (
		<>
			{ showConfirm ?
				<>
					<Button
						variant='contained'
						color='error'
						size='small'
						onClick={doDeleteProject}
						startIcon={<DeleteForeverIcon />}
					>Confirm</Button>
					<Button
						variant='contained'
						color='error'
						size='small'
						onClick={() => setConfirm(false)}
						startIcon={<CancelIcon />}
					>Cancel</Button>
				</>
				:
				<IconButton color='error' onClick={() => setConfirm(true)}>
					<DeleteForeverIcon />
				</IconButton>
			}
		</>
	)
}

export default DeleteProject