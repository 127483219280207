import React from 'react';
import toast from 'react-hot-toast';

import {
	defaultProject,
	doProjectUpload,
	doProjectUpdate,
	doProjectDelete
} from '../api/api';

import DeleteProject from './DeleteProject';
import ProjectSelector from './ProjectSelector';

import { IconButton, Button, ButtonGroup, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import styles from '../css/MainPanel.module.css'
import { Project } from '../Helpers/Types/ProjectTypes';

type Props = {
	isLoggedIn: boolean; 
	project: Project;
	setProject: (project: Project) => void;
	projects: Project[];
	resetProjectList: () => void;
}

const ProjectControls = ({ isLoggedIn, projects, project, setProject, resetProjectList }: Props) => {

	const [showTitleEditor, setShowEditor] = React.useState(false);
	const [newTitle, setNewTitle] = React.useState('');

	const saveNewTitle = async () => {

		var proj = null;

		if (project.pid)
			proj = await doProjectUpdate({ ...project, title: newTitle });
		else
			proj = await doProjectUpload({ ...project, title: newTitle });

		if (proj) {
			setProject(proj);
			setShowEditor(false);
			resetProjectList();
			toast.success('Successfully changed project title.');
		} else {
			toast.error('Couldn\'t change project title.');
		}
	}
	
	const createNewProject = async () => {
		
		// TODO: Check if unsaved project should be saved.

		if (project.pid) {

			const updatedProj = await doProjectUpdate(project);
			
			if (updatedProj) {
				setProject(defaultProject);
			} else {
				toast.error('Couldn\'t save current project.');
			}

		} else {
			setProject(defaultProject);
		}
	}

	const saveProject = async () => {

		var proj = null;

		if (project.pid)
			proj = await doProjectUpdate(project);
		else
			proj = await doProjectUpload(project);
		
		if (proj) {
			setProject(proj);
			resetProjectList();
			toast.success('Updated project.');
		} else {
			toast.error('Couldn\'t upload project.');
		}
  }

	const deleteProject = async () => {

		if (project.pid) {
			
			const proj = await doProjectDelete(project.pid);

			if (proj) {
				setProject(defaultProject);
				resetProjectList();
				toast.success('Deleted "' + proj.title + '".');
			} else {
				toast.error('Couldn\'t delete project.');
			}
		
		} else {
			toast.error('Cannot delete an unsaved project.');
		}
	}

	const titleEditor = () => {

		return (
			<div>
				<div className={styles.projectTitleEditButtons}>
					<Button
						variant='contained'
						color='primary'
						size='small'
						startIcon={<SaveIcon />}
						onClick={saveNewTitle}
					>Save Title</Button>
					<Button
						variant='contained'
						color='error'
						size='small'
						onClick={() => setShowEditor(false)}
						startIcon={<CancelIcon />}
					>Cancel</Button>
				</div>
				<TextField
					id="project-title-editor-textfield"
					label="Project Title"
					sx={{ width: '90%', mx: '5%' }}
					value={newTitle}
					onChange={(e) => setNewTitle(e.target.value)}
				/>
			</div>
		);
	}

	const projectControls = () => {

		return (
			<div>
				{ showTitleEditor ? titleEditor() :
					<div>
						<div className={styles.projectControlButtons}>
							<IconButton color='secondary' onClick={() => {setNewTitle(project.title); setShowEditor(true)}}>
								<EditIcon />
							</IconButton>
							<IconButton color='primary' onClick={saveProject}>
								<SaveIcon />
							</IconButton>
							<DeleteProject deleteProject={deleteProject} />
							<IconButton color='success' onClick={createNewProject}>
								<AddCircleIcon />
							</IconButton>
						</div>
						<ProjectSelector
							projects={projects}
							project={project}
							setProject={setProject}
						/>
					</div>
				}
			</div>
		);
	}

	return (
		<div>
			{ isLoggedIn ? projectControls() :
				<div>
					<ProjectSelector
						projects={projects}
						project={project}
						setProject={setProject}
					/>
					<Typography className={styles.signInTypeography}>Sign in or sign up to save project.</Typography>
				</div>
			}
		</div>
	);
}

export default ProjectControls