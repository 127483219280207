import React, { ReactElement, ReactNode, useState } from 'react';
import Modal from '@mui/material/Modal';
import styles from '../../css/Loop.module.css'

type Props = {
	open:  boolean;
	handleOnClose: () => void;
	children: ReactElement;
}

const GenericModal = ({ open, handleOnClose, children }: Props) => {
  return (
	  <Modal style={{display:'flex', alignItems:'center', justifyContent:'center'}} open={open} onClose={handleOnClose}>
		  {children}
	  </Modal>
  )
}

export default GenericModal