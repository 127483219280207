import React from 'react';
import { defaultProject } from '../api/api';
import { Autocomplete, TextField } from '@mui/material';
import { Project } from '../Helpers/Types/ProjectTypes';

type Props = {
	project: Project;
	setProject: (project: Project) => void;
	projects: Project[];
}

const ProjectSelector = ({ projects, project, setProject }: Props) => {

	const handleChange = async (event: any, value: Project | string | null, reason: any, details: any) => {

		// TODO: Check if current project is saved.

		if (value === null) {
			// setProject({ ...defaultProject, title: '' });
		} else if (typeof value === 'string') {
			// setProject();
		} else {
			setProject(value);
		}
	}

	return (
		<Autocomplete
			id="project-selector"
			freeSolo
			clearOnBlur
			selectOnFocus
			handleHomeEndKeys
			disableClearable
			sx={{ width: '90%', paddingTop: '5%' }}
			size='small'
			value={project}
			options={projects}
			getOptionLabel={(option) => {
				if (typeof option === 'string')
					return option;
				else
					return option.title;
			}}
			onChange={handleChange}
			// onClose={(event) => {
			// 	if (typeof event.target.value === 'string' && event.target.value !== project.title)
			// 		setProject(project);
			// }}
			renderOption={(props, option) => <li {...props} key={option.pid}>{option.title}</li>}
			renderInput={(params) => <TextField {...params} label="Project Selector" />}
		/>
	)
}

export default ProjectSelector