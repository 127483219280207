import React, { useState } from 'react';
import { API } from '../../api/api';
import ForgotPassword from '../Login/ForgotPassword'

import { TextField, Typography } from '@mui/material';
import styles from '../../css/NavBar.module.css';

type Props = {
	handleClose: () => void;
	doSignIn:  (email: string, password: string) => Promise<void>;
}

const LoginForm = ({ handleClose, doSignIn }: Props) => {

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPass, setConfirmPass] = useState('');
	const [nickname, setNickname] = useState('');
	const [errMsg, setErr] = useState('');
	const [forgPass, setForgPass] = useState(false);

	const toggleForgotPop = () => {
		setForgPass(!forgPass);
	}

	const setAndCheckConfirmPass = (pass: string) => {

		setConfirmPass(pass);
		if (password === pass)
			setErr('');
		else
			setErr('Passwords do not match.');
	}

	const doSignUp = () => {

		if (password !== confirmPass) {
			console.log('Could not sign up. Passwords did not match.');
			setErr('These passwords do not match.');
			return;
		}

		API.post('/user/signup', { nickname, email, password })
			.then((response) => {
				setErr(response.data.message);
			})
			.catch((error) => {
				console.log(error);
				setErr('Could not sign up.');
			});
	}

	return (
		<div className={styles.loginForm}>
			<h1 style={{marginTop: 0}}>Welcome Back!</h1>
			<input className={styles.input} placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
            <input className={styles.input} placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
			<Typography>{errMsg}</Typography>
			<div className={styles.formButtons}>
				<button className={styles.cancelButton} onClick={handleClose}>Cancel</button>
				<button className={styles.loginButton} onClick={() => doSignIn(email, password)}>Submit</button>
			</div>

			{/* <ForgotPassword open={forgPass} toggle={toggleForgotPop} /> */}

		</div>
	)
}

export default LoginForm
