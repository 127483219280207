import React, { ReactElement, ReactNode, useState } from 'react';
import Modal from '@mui/material/Modal';
import styles from '../../css/Loop.module.css'
import GenericModal from './GenericModal';
import { Box, Button } from '@mui/material';

type Props = {
	open: boolean;
	handleOnClose: () => void;
	handleOnConfirm: () => void;
}

const GenericConfirmation = ({ open, handleOnClose, handleOnConfirm }: Props) => {
	return (
		<GenericModal {...{open, handleOnClose}}>
			<Box>
				<Button>Confirm</Button>
				<Button>Cancel</Button>
			</Box>
		</GenericModal>
	)
}

export default GenericConfirmation