import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import SignUpForm from './SignUpForm';

// import ModalDialog from './ModalDialog';
import styles from '../../css/NavBar.module.css';
import LoginForm from './LoginForm';

type Props = {
	buttonText: string;
	doSignIn:  (email: string, password: string) => Promise<void>;
}

const LoginSignup = ({ buttonText, doSignIn }: Props) => {

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
	}

	return (
		<div>

			{buttonText == 'Sign Up' ?
			<> 
				<button className={styles.loginButton} onClick={handleOpen}>Sign Up</button>
				<Dialog 
					PaperProps={{
						style: { margin: '0', padding: '0', borderRadius: '15px' }
				  	}}
					open={open} 
					onClose={handleClose}>
					<SignUpForm handleClose={handleClose} />
				</Dialog>
			</> 
			: 
			<>
				<button className={styles.loginButton} onClick={handleOpen}>Login</button>
				<Dialog 
					PaperProps={{
						style: { margin: '2', padding: '2', borderRadius: '15px' }
				  	}}
					open={open} 
					onClose={handleClose}>
					<LoginForm doSignIn={doSignIn} handleClose={handleClose} />
				</Dialog>
			</> 
			}
		</div>
	)
}

export default LoginSignup
