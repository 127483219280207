import React, {useState} from 'react'

const useModal = () => {

	// declare a new state variable for modal open
	const [open, setOpen] = useState(false);

	// function to handle modal open
	const handleOpen = () => {
		console.log(open);
		setOpen(true);
	};

	// function to handle modal close
	const handleClose = () => {
		console.log(open)
		setOpen(false);
	};

  return (
	{open, handleOpen, handleClose}
  )
}

export default useModal;
