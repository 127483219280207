import React from 'react'
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom"

import './css/App.css'

import Home from './pages/Home'
import ResetPass from './pages/ResetPass'
import VerificationPage from './pages/VerificationPage'

function App() {
	
	return (
		<Router>
			<Routes>
				<Route path="/" element={ <Home /> }/>
				{/* <Route path="/reset-password/:token" element={ <ResetPass /> }/> */}
				{/* <Route path="/verify-email/:token" element={ <VerificationPage /> }/> */}
			</Routes>
		</Router>
	)
}

export default App