import React from 'react';

import { TextField, Select, FormControl, MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';

// import styles from '../css/MainPanel.module.css'
import { Project } from '../Helpers/Types/ProjectTypes';
import { NoteName } from '../Helpers/Types/MusicTypes'

type Props = {
	project: Project;
	setProject: (project: Project) => void;
}

const KeyModeBPMControls = ({ project, setProject }: Props) => {

	const keys: NoteName[] = ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#' ];
	const modes = ['Major', 'Ionian', 'Dorian', 'Phrygian', 'Lyndian', 'Myxolydian', 'Aeolian', 'Locrian', 'Minor'];

	const updateProjectKey = (value: NoteName) => {
		setProject({ ...project, key: value });
	}

	const updateProjectMode = (value: string) => {
		setProject({ ...project, mode: value });
	}

	const updateProjectBPM = (value: number) => {
		if (value < 0) {
			setProject({ ...project, bpm: 0 });
		} else if (value > 200) {
			setProject({ ...project, bpm: 200 });
		} else {
			setProject({ ...project, bpm: value });
		}
	}

	return (
		<div>
			<FormControl sx={{ mx: 1, my: 2, minWidth: 80, }} size="small">
				<InputLabel id="key-input-label">Key</InputLabel>
				<Select
					labelId="key-selector"
					id="key-selector"
					value={project.key}
					label="Key"
					onChange={(event: SelectChangeEvent) => updateProjectKey(event.target.value as NoteName)}
					>
					{keys.map(key => <MenuItem value={key}>{key}</MenuItem>)}
				</Select>
			</FormControl>
			<FormControl sx={{ mx: 1, my: 2, minWidth: 130 }} size="small">
				<InputLabel id="mode-input-label">Mode</InputLabel>
				<Select
					labelId="mode-selector"
					id="mode-selector"
					value={project.mode}
					label="Mode"
					onChange={(event: SelectChangeEvent) => updateProjectMode(event.target.value)}
					>
					{modes.map(mode => <MenuItem value={mode}>{mode}</MenuItem>)}
				</Select>
			</FormControl>
			<TextField
				id="bpm-textfield"
				label="BPM"
				type="number"
				size="small"
				sx={{ mx: 1, my: 2, width: 100 }}
				value={project.bpm}
				InputLabelProps={{
					shrink: true,
				}}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateProjectBPM(event.target.value as unknown as number)}
			/>
		</div>
	)
}

export default KeyModeBPMControls