import React from 'react';
import NavBar from '../components/NavBar';
import { useCookies } from 'react-cookie';
import MainPanel from '../components/ChordProgression/MainPanel';
import toast, { Toaster } from 'react-hot-toast';
import { Project } from '../Helpers/Types/ProjectTypes';
import { API, defaultProject, getProjects, doProjectUpdate } from '../api/api';


const Home = () => {
	const [cookies, setCookie, removeCookie] = useCookies();
	const [isLoggedIn, setLogin] = React.useState(cookies.nickname ? true : false);
	const [nickname, setNickname] = React.useState(cookies.nickname || 'Music Lover');
	
	const [project, setProjState] = React.useState(cookies.project || defaultProject);
	const [projects, setProjects] = React.useState<Project[]>([]);

	React.useEffect(() => {
		if (isLoggedIn)
			getProjects()
				.then((projects) => {
					if (projects) {
						setProjects(projects);
					} else {
						setLogin(false);
						toast.error('Could not authenticate user.');
					}
				});
	}, [isLoggedIn]);

	const setProject = (project: Project) => {
		setProjState(project);
		setCookie('project', project, { path: '/' });
	}

	const resetProjectList = () => {
		getProjects()
			.then((projects) => {
				if (projects)
					setProjects(projects);
				else
					toast.error('Could not load user projects.');
			});
	}

	const doSignIn = async (email: string, password: string) => {
		try {
			const response = await API.post('/user/signin', { email, password });

			setCookie('nickname', response.data.nickname, { path: '/' });
			setNickname(response.data.nickname);
			setLogin(true);

			resetProjectList();

		} catch (error: any) {

			console.log('Error while logging in:', error);
			if (error.response.data && error.response.data.message)
				toast.error(error.response.data.message);
			else
				toast.error('Could not sign in.');
		}
	}

	const doSignOut = async () => {
		try {
			if (project.pid) {
				const updatedProject = await doProjectUpdate(project);
				if (!updatedProject) return;
				
				setProject(defaultProject);
				removeCookie('project');
			}

			// TODO: Check if project with no pid should be saved.

			await API.delete('/user/signout');

			removeCookie('nickname');
			setProjects([]);
			setLogin(false);

			toast.success('Successfully signed out.');

		} catch (error: any) {

			console.log('Error while logging out:', error);
			if (error.response.data && error.response.data.message)
				toast.error(error.response.data.message);
			else
				toast.error('Could not sign out.');
		}
	}

	return (
		<div>
			<Toaster
				position="top-center"
				reverseOrder={false}
				toastOptions={{ duration: 3000 }}
			/>
			<NavBar
				isLoggedIn={isLoggedIn}
				nickname={nickname}
				doSignIn={doSignIn}
				doSignOut={doSignOut}
			/>
			<MainPanel
				isLoggedIn={isLoggedIn}
				project={project}
				setProject={setProject}
				projects={projects}
				resetProjectList={resetProjectList}
			/>
		</div>
	)
}

export default Home