import { Chord, Mode } from "@tonaljs/tonal"
import { ChordType } from "@tonaljs/chord-type";
import { Suggestion } from "../../Helpers/Types/ProjectTypes";

export class Suggestor {
    suggestions: Suggestion[] = []

    getFifth(key: string, mode: string) {
        const item: Suggestion = {
			suggestion: "The fifth chord of a scale is commonly used as a perfect resolution (perfect cadence) to the first (tonic) chord of the scale.",
        	chord: Mode.triads(mode, key)[4]
		}
        this.suggestions.push(item)
    }

    getFourth(key: string, mode: string) {
        const item = {
			suggestion: "The fourth chord of a scale is commonly used as an imperfect resolution (plagal cadence) to the first (tonic) chord of the scale.",
        	chord: Mode.triads(mode, key)[3]
		}
        this.suggestions.push(item)
    }

	static diatonics(key: string, mode: string) {
        const items = []
		const numbers = ["first", "second", "third", "forth", "fifth", "sixth", "seventh"];
		for (let i = 0; i < 7; i++) {
			items.push({ key: Mode.triads(mode, key)[i], value: `The ${numbers[i]} chord of the scale`});
		}
		
        // items.push({"The first chord of the scale.": Mode.triads(mode, key)[0]})
        // items.push({"The second chord of the scale.": Mode.triads(mode, key)[1]})
        // items.push({"The third chord of the scale.": Mode.triads(mode, key)[2]})
        // items.push({"The fourth chord of the scale.": Mode.triads(mode, key)[3]})
        // items.push({"The fifth chord of the scale.": Mode.triads(mode, key)[4]})
        // items.push({"The sixth chord of the scale.": Mode.triads(mode, key)[5]})
        // items.push({"The seventh chord of the scale.": Mode.triads(mode, key)[6]})
        // items.forEach(element => {
        //      this.suggestions.push(element)
        // });

		return items;
    }

	numberBuilder (n: number) {
		var special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
		var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

		
		if (n < 20) return special[n];
		if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
		return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
		
	}

	diatonicsSevenths(key: string, mode: string) {
        const items = []

        // items.push({"The first seven chord of the scale": Mode.seventhChords(mode, key)[0]})
        // items.push({"The second seven chord of the scale": Mode.seventhChords(mode, key)[1]})
        // items.push({"The third seven chord of the scale": Mode.seventhChords(mode, key)[2]})
        // items.push({"The fourth seven chord of the scale": Mode.seventhChords(mode, key)[3]})
        // items.push({"The fifth seven chord of the scale": Mode.seventhChords(mode, key)[4]})
        // items.push({"The sixth seven chord of the scale": Mode.seventhChords(mode, key)[5]})
        // items.push({"The seventh seven chord of the scale": Mode.seventhChords(mode, key)[6]})

		for (let i = 1; i <= 7; i++) {
			items.push({
				suggestion: `The ${this.numberBuilder(i)} seven chord of the scale`,
				chord: Mode.seventhChords(mode, key)[i-1]
			})
		}

        items.forEach(element => {
            this.suggestions.push(element)
        });
    }
    
    static allChords() {
        const majorTriads = ["Amaj", "A#maj", "Bmaj", "Cmaj", "C#maj", "Dmaj", "D#maj", "Emaj", "Fmaj", "F#maj", "Gmaj", "G#maj"]
        const minorTriads = ["Amin", "A#min", "Bmin", "Cmin", "C#min", "Dmin", "D#min", "Emin", "Fmin", "F#min", "Gmin", "G#min"]
        const majorSevenths = ["Amaj7", "A#maj7", "Bmaj7", "Cmaj7", "C#maj7", "Dmaj7", "D#maj7", "Emaj7", "Fmaj7", "F#maj7", "Gmaj7", "G#maj7"]
        const minorSevenths = ["Amin7", "A#min7", "Bmin7", "Cmin7", "C#min7", "Dmin7", "D#min7", "Emin7", "Fmin7", "F#min7", "Gmin7", "G#min7"]
        let extensions: any = []

        majorSevenths.forEach(element => {
            Chord.extended(element).forEach(elementTwo => {
                extensions.push(elementTwo)
            })
        });
        
        minorSevenths.forEach(element => {
            Chord.extended(element).forEach(elementTwo => {
                extensions.push(elementTwo)
            })
        });

        console.log("All chords")
        console.log(majorTriads)
        console.log(majorSevenths)
        console.log(minorTriads)
        console.log(minorSevenths)
        console.log(extensions)

        return [...majorTriads, ...minorTriads, ...majorSevenths, ...minorSevenths, ...extensions]
    }
}